import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ModalType, PERMISSIONS } from 'constants/common';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import AddSourceAccessModal from './components/AddSourceAccessModal';
import GameTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetListDomainAccess, useQueryGetListIP } from 'services/system';
import { Select } from 'chakra-react-select';
import DeleteSourceAccessModal from './components/DeleteSourceAccessModal';

function AccessPermission() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [textEditing, setTextEditing] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { userInfo } = useUserState();
  const [searchTitle, setSearchTitle] = useState('');

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.Delete]: onOpenDeleteModal,
    }),
    [onOpenCreateModal, onOpenDeleteModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.Delete]: onCloseDeleteModal,
    }),
    [onCloseCreateModal, onCloseDeleteModal]
  );

  const { data: listIPData, refetch } = useQueryGetListDomainAccess({ ...filter }, { enabled: CookieStorage.isAuthenticated() });

  const handleUpdateItem = (itemDetail, modalType) => {
    openModal?.[modalType]?.();
    setTextEditing(itemDetail);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setTextEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const onChangeSearchTitle = value => {
    setSearchTitle(value);
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchTitle('');
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    QUẢN LÝ HỆ THỐNG {'>'} CHẶN QUYỀN TRUY CẬP
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={PERMISSIONS.CREATE_WHITELIST}>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                </AuthorizationComponentWrapper>
              </Flex>
            </Flex>
            {/* <Flex flexDirection="column" gap={'20px'} mt="12px">
              <Flex alignItems={'center'} gap={'16px'} flexWrap="wrap">
                <FormControl w={{ base: 'full', sm: '250px' }}>
                  <FormLabel h={'21px'}>Tìm kiếm</FormLabel>
                  <Input value={searchTitle.searchKeyword} onChange={e => onChangeSearchTitle(e.target.value)} />
                </FormControl>
              </Flex>
              <Flex justifyContent="flex-end" gap="12px">
                <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Cài lại
                  </Text>
                </Button>
                <Button bg="#3182ce" color="#fff" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Tìm kiếm
                  </Text>
                </Button>
              </Flex>
            </Flex> */}
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <GameTable tableData={listIPData?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
            </Stack>
            {!isEmpty(listIPData?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={listIPData?.pagination?.page}
                  pageLength={listIPData?.pagination?.pageSize}
                  totalRecords={listIPData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isCreateModalOpen && (
        <AddSourceAccessModal isOpen={isCreateModalOpen} ipDetail={textEditing} onClose={handelCloseModal} refetch={refetch} />
      )}
      {isDeleteModalOpen && (
        <DeleteSourceAccessModal isOpen={isDeleteModalOpen} textDetail={textEditing} onClose={handelCloseModal} refetch={refetch} />
      )}
    </>
  );
}

export default AccessPermission;
