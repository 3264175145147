import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { ModalType } from 'constants/common';
import { IPFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import InputController from 'components/Form/InputController';
import { useCreateIPMutation, useUpdateIPMutation } from 'services/system';

const CreateTextModal = ({ ipDetail, isOpen, onClose, refetch }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(IPFormValidate),
    defaultValues: {
      name: '',
      ip: '',
    },
  });

  const createIPMutation = useCreateIPMutation();
  const updateIPMutation = useUpdateIPMutation();

  useEffect(() => {
    if (!isEmpty(ipDetail)) {
      reset({
        name: ipDetail?.name,
        ip: ipDetail?.ip,
      });
    }
  }, [ipDetail]);

  const onSubmit = values => {
    const dataSubmit = {
      ...values,
    };

    if (ipDetail) {
      updateIPMutation.mutate(
        {
          ...dataSubmit,
          id: ipDetail?._id,
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Chỉnh sửa IP thành công');
            onClose(ModalType.Add);
            refetch();
          },
        }
      );
      return;
    }

    createIPMutation.mutate(
      {
        ...dataSubmit,
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tạo mới IP thành công');
          onClose(ModalType.Add);
          refetch();
        },
      }
    );
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        {/* <AlertDialogHeader textTransform="uppercase">{`Tạo trò chơi`}</AlertDialogHeader> */}
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <InputController control={control} name="name" label="Định Danh" isRequired />
            <InputController control={control} name="ip" label="IP" isRequired styleContainer={{ pt: 4 }} />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center">
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button
              colorScheme="blue"
              isLoading={createIPMutation.isPending || updateIPMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateTextModal;
